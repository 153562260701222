// src/pages/LoginPage.js
import React from 'react';
import { Container } from 'react-bootstrap';
import Login from '../components/auth/Login';
import './LoginPage.css';

const LoginPage = () => {
  return (
      <Container className="login-page-container">
        <div className="login-page-box">
          <Login />
        </div>
      </Container>
  );
};

export default LoginPage;
