// src/components/auth/ResetPassword.js
import React, { useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './ResetPassword.css'; // Import the CSS file

const ResetPassword = () => {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage('');
      setError('');
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage('Check your inbox for further instructions');
    } catch {
      setError('Failed to reset password');
    }

    setLoading(false);
  }

  return (
    <Container className="reset-password-container">
      <div className="reset-password-box">
        <h2 className="text-center mb-4">Password Reset</h2>
        {message && <Alert variant="success">{message}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group id="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" ref={emailRef} required />
          </Form.Group>
          <Button disabled={loading} className="w-100 mt-3" type="submit">
            Reset Password
          </Button>
        </Form>
        <div className="text-center mt-3">
          <Link to="/login">Log In</Link>
        </div>
        <div className="text-center mt-3">
          <Link to="/register">Need an account? Sign Up</Link>
        </div>
      </div>
    </Container>
  );
};

export default ResetPassword;
