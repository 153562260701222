// src/pages/ContactPage.js
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ContactForm from '../components/contactform/ContactForm';
import './ContactPage.css';

const ContactPage = () => (
    <GoogleReCaptchaProvider
      reCaptchaKey="6Lcva_kpAAAAAApD9LxB0MGJZCyIQHwQ2FOh2PBq"
      scriptProps={{ async: true, defer: true, appendTo: 'body', nonce: 'recaptcha-nonce' }}
    >
      <ContactForm />
    </GoogleReCaptchaProvider>
);

export default ContactPage;
