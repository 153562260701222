import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import {
  Container,
  TextField,
  Button,
  Alert,
  Typography,
  Box,
} from '@mui/material';

const ProfilePage = () => {
  const { currentUser, updateUserName, updateUserPassword } = useAuth();
  const [firstName, setFirstName] = useState(currentUser?.displayName?.split(' ')[0] || '');
  const [lastName, setLastName] = useState(currentUser?.displayName?.split(' ')[1] || '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    if (password !== confirmPassword) {
      return setError('Passwords do not match');
    }

    const promises = [];
    setLoading(true);
    setError('');
    setMessage('');

    const displayName = `${firstName} ${lastName}`;
    if (displayName !== currentUser.displayName) {
      promises.push(updateUserName(displayName));
    }
    if (password) {
      promises.push(updateUserPassword(password));
    }

    Promise.all(promises)
      .then(() => {
        setMessage('Profile updated successfully');
      })
      .catch(() => {
        setError('Failed to update profile');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Container maxWidth="sm" sx={{ padding: 4, marginTop: 8, display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ padding: 4, backgroundColor: '#ffffff', borderRadius: 1, boxShadow: 3, textAlign: 'center', maxWidth: 600, width: '100%' }}>
        <Typography variant="h4" component="h2" align="center" gutterBottom>
          Profile
        </Typography>
        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        {message && <Alert severity="success" sx={{ mt: 2 }}>{message}</Alert>}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
          <TextField
            margin="normal"
            fullWidth
            id="first-name"
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <TextField
            margin="normal"
            fullWidth
            id="last-name"
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <TextField
            margin="normal"
            fullWidth
            id="password"
            label="Password"
            type="password"
            placeholder="Leave blank to keep the same"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            id="confirm-password"
            label="Confirm Password"
            type="password"
            placeholder="Leave blank to keep the same"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ mt: 3 }}
          >
            Update Profile
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ProfilePage;
