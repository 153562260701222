import { useState, useEffect } from 'react';
import { createNewThread, fetchThread } from '../services/api';
import { runFinishedStates } from './constants';

export const useThread = (run, setRun) => {
    const [threadId, setThreadId] = useState(undefined);
    const [thread, setThread] = useState(undefined);
    const [actionMessages, setActionMessages] = useState([]);
    const [messages, setMessages] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const initThread = async () => {
            try {
                const localThreadId = localStorage.getItem('thread_id');
                if (localThreadId) {
                    console.log(`Resuming thread ${localThreadId}`);
                    setThreadId(localThreadId);
                    const fetchedThread = await fetchThread(localThreadId);
                    setThread(fetchedThread);
                } else {
                    console.log('Creating new thread');
                    const data = await createNewThread();
                    setRun(data);
                    setThreadId(data.thread_id);
                    localStorage.setItem('thread_id', data.thread_id);
                    console.log(`Created new thread ${data.thread_id}`);
                }
            } catch (err) {
                setError('Failed to initialize thread');
                console.error(err);
            }
        };
        if (!threadId) {
            initThread();
        }
    }, [threadId, setRun]);

    useEffect(() => {
        if (!run || !runFinishedStates.includes(run.status)) {
            return;
        }

        const retrieveThread = async () => {
            try {
                console.log(`Retrieving thread ${run.thread_id}`);
                const threadData = await fetchThread(run.thread_id);
                setThread(threadData);
            } catch (err) {
                setError('Failed to retrieve thread');
                console.error(err);
            }
        };
        retrieveThread();
    }, [run]);

    useEffect(() => {
        if (!thread) {
            return;
        }
        console.log('Transforming thread into messages');

        const newMessages = [...thread.messages, ...actionMessages]
            .sort((a, b) => a.created_at - b.created_at)
            .filter((message) => message.hidden !== true);
        setMessages(newMessages);
    }, [thread, actionMessages]);

    const clearThread = () => {
        localStorage.removeItem('thread_id');
        setThreadId(undefined);
        setThread(undefined);
        setRun(undefined);
        setMessages([]);
        setActionMessages([]);
    };

    return {
        threadId,
        messages,
        actionMessages,
        setActionMessages,
        clearThread,
        error,
    };
};
