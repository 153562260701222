import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth } from '../../firebase';
import { Container, TextField, Button, Grid, Typography, Alert } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import './Register.css';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { loginWithGoogle } = useAuth();
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      setError('');
      setLoading(true);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await updateProfile(user, { displayName: `${firstName} ${lastName}` });
      navigate('/chat');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleRegister = async () => {
    try {
      setError('');
      setLoading(true);
      await loginWithGoogle();
      navigate('/chat');
    } catch (error) {
      setError('Failed to register with Google');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="register-container">
      <div className="register-box">
        <Typography variant="h4" align="center" gutterBottom>
          Register
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <form onSubmit={handleRegister}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} className="grid-item">
              <TextField
                fullWidth
                id="first-name"
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} className="grid-item">
              <TextField
                fullWidth
                id="last-name"
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} className="grid-item">
              <TextField
                fullWidth
                id="email"
                label="Email"
                type="email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} className="grid-item">
              <TextField
                fullWidth
                id="password"
                label="Password"
                type="password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Grid>
          </Grid>

          <div className="button-container">
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              className="register-button"
              type="submit"
            >
              Register
            </Button>
          </div>

          <div className="button-container">
            <Button
              disabled={loading}
              variant="contained"
              color="secondary"
              className="google-register-button"
              onClick={handleGoogleRegister}
            >
              Register with Google
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default Register;
