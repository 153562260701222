import React from 'react';
import { ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from '@mui/material';
import Markdown from 'react-markdown';
import { SiOpenai } from "react-icons/si";
import remarkGfm from "remark-gfm";
import './Message.css';

const Message = ({ message, role }) => {
    const roleIcon = role === "user"
        ? (
            <Avatar className="avatar user-avatar">
                C
            </Avatar>
        ) : (
            <Avatar className="avatar bot-avatar">
                <SiOpenai />
            </Avatar>
        );

    const roleName = role === "user" ? "Coder" : "KieCodesGPT";

    return (
        <ListItem className={`message ${role}`}>
            <ListItemAvatar>
                {roleIcon}
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Typography component="span" variant="body2" className="role-name">
                        {roleName}
                    </Typography>
                }
                secondary={
                    <Markdown className="message-content" remarkPlugins={[remarkGfm]}>
                        {message}
                    </Markdown>
                }
            />
        </ListItem>
    );
};

export default Message;
