import axios from 'axios';

const API_URL = 'https://dev.prodevautomation.com/api';

export const createNewThread = async () => {
    try {
        const response = await axios.post(`${API_URL}/new`);
        return response.data;
    } catch (error) {
        console.error('Error creating new thread:', error);
        throw error;
    }
};

export const fetchThread = async (threadId) => {
    try {
        const response = await axios.get(`${API_URL}/threads/${threadId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching thread:', error);
        throw error;
    }
};

export const fetchRun = async (threadId, runId) => {
    try {
        const response = await axios.get(`${API_URL}/threads/${threadId}/runs/${runId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching run:', error);
        throw error;
    }
};

export const postMessage = async (threadId, message) => {
    try {
        const response = await axios.post(`${API_URL}/threads/${threadId}`, { content: message });
        return response.data;
    } catch (error) {
        console.error('Error posting message:', error);
        throw error;
    }
};

export const postToolResponse = async (threadId, runId, toolResponses) => {
    try {
        const response = await axios.post(`${API_URL}/threads/${threadId}/runs/${runId}/tool`, toolResponses, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error posting tool response:', error);
        throw error;
    }
};
