import React from 'react';
import { Container, Button, Typography, Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import './HomePage.css';
import placeholderImage from '../assets/aicoachhome.jpg'; // Adjust the path if necessary

const HomePage = () => {
  return (
      <div className="home-content">
        <Container maxWidth="md" className="home-container">
          <Box className="home-box">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <img src={placeholderImage} alt="Placeholder" className="home-image" />
              </Grid>
              <Grid item xs={12} sm={6} className="text-container">
                <Typography variant="h2" component="h1" gutterBottom className="home-title">
                  Welcome to CoachME
                </Typography>
                <Typography variant="h5" component="p" className="home-description">
                  CoachME is your AI Assistant, helping you train for difficult conversations such as salary raises, promotions, or any other challenging discussions.
                </Typography>
                <Box mt={4} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/register"
                    className="btn btn-success login-button w-50 mt-3"  
                  >
                    Start Trial
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
  );
};

export default HomePage;
