import React, { useState } from 'react';
import { Container, List, Box, Typography } from '@mui/material';
import Message from './Message';
import MessageInput from './MessageInput';
import ChatStatusIndicator from './ChatStatusIndicator';
import Loading from './Loading';
import { useThread } from '../../hooks/useThread';
import { useRunStatus } from '../../hooks/useRunStatus';
import { useRunRequiredActionsProcessing } from '../../hooks/useRunRequiredActionsProcessing';
import { useRunPolling } from '../../hooks/useRunPolling';
import { getBotResponse } from '../../services/chatService'; // Kept `getBotResponse` from HEAD
import { postMessage } from '../../services/api'; // Kept `postMessage` from merged branch
import './ChatWindow.css';

const ChatWindow = () => {
  const [run, setRun] = useState(null);
  const { threadId, messages, setActionMessages, clearThread, error: threadError } = useThread(run, setRun);
  const { status, processing } = useRunStatus(run);
  const [error, setError] = useState(null);

  useRunRequiredActionsProcessing(run, setRun, setActionMessages);
  useRunPolling(threadId, run, setRun);

  const handleSendMessage = async (message) => {
    if (message.trim() === '') return;

    const userMessage = { content: message, role: 'user' };
    setActionMessages((prev) => [...prev, userMessage]);

    if (threadId) {
      try {
        const botResponse = await postMessage(threadId, message); // Sending via `postMessage`
        const botMessage = { content: botResponse.content, role: 'bot' };
        setActionMessages((prev) => [...prev, botMessage]);
      } catch (error) {
        console.error('Failed to send message:', error);
        setError('Failed to send message. Please try again later.');
      }
    } else {
      console.error('Thread ID is not defined');
      setError('Thread ID is not defined');
    }
  };

  return (
    <Container className="chat-window">
      <Box className="chat-box">
        {error && <Typography color="error">{error}</Typography>}
        {threadError && <Typography color="error">{threadError}</Typography>}
        <List className="message-list">
          {messages.map((message, index) => (
            <Message key={index} message={message} />
          ))}
        </List>
        {status && <ChatStatusIndicator status={status} />}
        <MessageInput onSend={handleSendMessage} />
        {processing && <Loading />} {/* Show Loading component when processing */}
      </Box>
    </Container>
  );
};

export default ChatWindow;
