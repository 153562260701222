import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { MdSend } from "react-icons/md";
import './MessageInput.css';

const MessageInput = ({ disabled, onSend }) => {
  const [input, setInput] = useState('');

  const handleSend = (e) => {
    e.preventDefault();
    if (input.trim() === '') return;
    onSend(input);
    setInput('');
  };

  return (
    <form
      className="message-input-form"
      onSubmit={handleSend}
      autoComplete="off"
    >
      <Box display="flex" mt={2} className="message-input">
        <TextField
          name="message"
          placeholder="How can I help you today?"
          variant="outlined"
          fullWidth
          value={input}
          onChange={(e) => setInput(e.target.value)}
          disabled={disabled}
          className="message-input-field"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={disabled}
          className="message-send-button"
          endIcon={<MdSend />}
          style={{ marginLeft: '1rem' }}
        >
          Send
        </Button>
      </Box>
    </form>
  );
};

export default MessageInput;
