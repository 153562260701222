import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import './AboutPage.css';
import conversationPracticeImage from '../assets/images/conversation_practice.jpg';
import aiTrainingImage from '../assets/images/ai_training.jpg';
import successStoriesImage from '../assets/images/success_stories.jpg';

const AboutPage = () => {
  return (
      <Container maxWidth="md" className="about-container">
        <Box className="about-box">
          <Typography variant="h2" component="h1" gutterBottom className="about-title">
            About CoachME
          </Typography>
          <Typography variant="h5" component="p" className="about-description">
            CoachME is your AI Assistant, helping you train for difficult conversations such as salary raises, promotions, or any other challenging discussions.
          </Typography>
          <Grid container spacing={2} className="about-content">
            <Grid item xs={12} sm={6}>
              <img src={conversationPracticeImage} alt="Conversation Practice" className="about-image" />
              <Typography variant="body1" component="p" className="about-text">
                CoachME leverages the power of artificial intelligence to simulate real-life scenarios and help you practice and improve your communication skills. Whether you're preparing for a job interview, a performance review, or a negotiation, CoachME is here to assist you in navigating through it with confidence.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img src={aiTrainingImage} alt="AI Training" className="about-image" />
              <Typography variant="body1" component="p" className="about-text">
                Our AI models are trained on a vast array of conversational data, ensuring that you receive realistic and practical guidance. With CoachME, you can practice at your own pace and get immediate feedback on your communication strategies.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <img src={successStoriesImage} alt="Success Stories" className="about-image" />
              <Typography variant="body1" component="p" className="about-text">
                Join thousands of users who have successfully enhanced their communication skills with CoachME. Whether you're aiming to excel in your career or improve your personal interactions, CoachME provides the tools you need to succeed.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
  );
};

export default AboutPage;
