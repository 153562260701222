import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, googleProvider } from '../firebase';
import { onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail, signOut, updateProfile } from 'firebase/auth';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  const loginWithEmailAndPassword = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error("Error during email sign-in:", error);
      throw error;
    }
  };

  const loginWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      throw error;
    }
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error("Error during password reset:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error during logout:", error);
      throw error;
    }
  };

  const updateUserName = async (name) => {
    if (currentUser) {
      try {
        await updateProfile(currentUser, { displayName: name });
        setCurrentUser({ ...currentUser, displayName: name });
      } catch (error) {
        console.error("Error updating user name:", error);
        throw error;
      }
    }
  };

  const updateUserPassword = async (password) => {
    if (currentUser) {
      try {
        await currentUser.updatePassword(password);
      } catch (error) {
        console.error("Error updating user password:", error);
        throw error;
      }
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, loginWithEmailAndPassword, loginWithGoogle, resetPassword, logout, updateUserName, updateUserPassword }}>
      {children}
    </AuthContext.Provider>
  );
};
