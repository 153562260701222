// src/components/contactform/ContactForm.js
import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';
import emailjs from '@emailjs/browser';
import './ContactForm.css';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name,
      email,
      message,
    };

    emailjs.send('service_hrcqlgb', 'template_fjqcljw', templateParams, 'AyV9tq3OazhdgSbhQ')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSuccess('Your message has been sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
      }, (err) => {
        console.error('FAILED...', err);
        setError('Failed to send your message. Please try again.');
      });
  };

  return (
    <
    Container maxWidth="sm" className="contact-container">
      <Box mt={5} className="contact-box">
        <Typography variant="h4" component="h1" gutterBottom>
          Contact Us
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            label="Message"
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
            required
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            className="contact-button"
          >
            Send Message
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default ContactForm;

