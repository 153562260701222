// src/pages/RegisterPage.js
import React from 'react';
import { Container } from 'react-bootstrap';
import Register from '../components/auth/Register';
import './RegisterPage.css';

const RegisterPage = () => {
  return (
      <Container className="register-page-container">
        <div className="register-page-box">
          <Register />
        </div>
      </Container>
  );
};

export default RegisterPage;
