import React, { useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { Container, TextField, Button, Typography, Alert } from '@mui/material'; // Import Material-UI components
import './Login.css';

const Login = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { loginWithEmailAndPassword, loginWithGoogle } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await loginWithEmailAndPassword(emailRef.current.value, passwordRef.current.value);
      navigate('/chat');
    } catch {
      setError('Failed to log in');
    }

    setLoading(false);
  }

  async function handleGoogleLogin() {
    try {
      setError('');
      setLoading(true);
      await loginWithGoogle();
      navigate('/chat');
    } catch {
      setError('Failed to log in with Google');
    }

    setLoading(false);
  }

  return (
    <Container className="login-container">
      <div className="login-box">
        <Typography variant="h4" align="center" gutterBottom>
          Log In
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            id="email"
            label="Email"
            type="email"
            variant="outlined"
            inputRef={emailRef}
            required
            margin="normal"
          />
          <TextField
            fullWidth
            id="password"
            label="Password"
            type="password"
            variant="outlined"
            inputRef={passwordRef}
            required
            margin="normal"
          />
          <div className="button-container">
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              className="login-button"
              type="submit"
            >
              Log In
            </Button>
          </div>
        </form>
        <div className="button-container">
          <Button
            disabled={loading}
            variant="contained"
            color="secondary"
            className="google-login-button"
            onClick={handleGoogleLogin}
          >
            Login with Google
          </Button>
        </div>
        <div className="text-center mt-3">
          <Typography variant="body1">
            Don't have an account? <Link to="/register">Create account</Link>
          </Typography>
        </div>
        <div className="text-center mt-3">
          <Typography variant="body1">
            <Link to="/reset-password">Forgot Password?</Link>
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default Login;
